import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Alert from "../../../components/alert/alert";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";

import {
  ORDER_STATUS,
  ORDER_TYPE,
  USER_ROLES,
} from "../../../core/constants/roles";

import { toast } from "react-toastify";
import {
  changeStatus,
  getCustomers,
  getOrders,
  setFix,
  setOrders,
} from "../../../resources/services";
import "./arreglo.css";

import { Loading } from "../../../components/loading/loading";
import {
  resetStoreAction,
  updateOrderAction,
} from "../../../redux/actions/orders";
import { ARREGLO_WITH_ORDER, ARREGLO_WITHOUT_ORDER } from "./utils";
import { AdminView } from "./views/admin";
import { ClientView } from "./views/client";

class Arreglo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ukey: null,
      price: null,
      status: ORDER_STATUS.PROPOSAL,
      description: "",
      orderSelected: {},
      customers: [],
      isLoading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnChangePrice = this.handleOnChangePrice.bind(this);
    this.handleOnChangeDescription = this.handleOnChangeDescription.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleChangeNewOrderForArreglo =
      this.handleChangeNewOrderForArreglo.bind(this);
    this.getAllCustomers = this.getAllCustomers.bind(this);
    this.createFixOrder = this.createFixOrder.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.props.resetStore();
  }

  handleInputChange(props) {
    if (
      props.value !== ARREGLO_WITHOUT_ORDER &&
      props.value !== ARREGLO_WITH_ORDER
    ) {
      const selected = this.state.data.filter(
        (data) => data.ukey === props.value
      )[0];
      this.setState({
        orderSelected: {
          ...selected,
          doneByFeetSolutions: this.state.orderSelected.doneByFeetSolutions,
        },
      });
    } else {
      this.setState({
        orderSelected: {
          ukey: props.value,
          doneByFeetSolutions: props.value,
        },
      });
    }
  }

  handleChangeStatus(status) {
    this.setState({
      status: status,
    });
    if (status === ORDER_STATUS.FABRICATION) {
      this.props.history.push(`/pedido/${this.state.ukey}`);
    } else {
      setTimeout(() => {
        this.props.history.push(`/pedido/arreglo/${this.state.ukey}`);
        this.loadOrders();
      }, 100);
    }
  }

  validationsSubmit() {
    // checks before submit
    const values = Object.values(this.state.orderSelected).map(
      (obj) => obj.value ?? obj
    );
    // check select Order
    if (this.state.orderSelected.ukey === null) {
      toast("Por favor, complete todos los campos", {
        type: toast.TYPE.ERROR,
      });
      return false;
    }

    // check if select new order, complete all fields
    if (
      this.state.orderSelected.ukey === ARREGLO_WITHOUT_ORDER &&
      (values.length === 1 || values.includes(""))
    ) {
      toast("Por favor, complete todos los campos", {
        type: toast.TYPE.ERROR,
      });
      return false;
    }
    return true;
    // end check
  }
  handleOnSubmit() {
    if (!this.validationsSubmit()) {
      return Promise.reject();
    }
    console.log(this.state);
    if (
      this.state.orderSelected.doneByFeetSolutions !== ARREGLO_WITHOUT_ORDER
    ) {
      return this.createFixOrder();
    } else {
      return this.prepareOrderToFix().then(() => this.createFixOrder());
    }
  }

  prepareOrderToFix() {
    const order = {
      ...this.props.order,
      status: ORDER_STATUS.ARREGLO,
      ...Object.keys(this.state.orderSelected)
        .filter((key) => key !== "ukey")
        .reduce((prev, key) => {
          prev[key] = this.state.orderSelected[key].value;
          return prev;
        }, {}),
    };
    delete order.ukey;

    return setOrders(order).then((response) => {
      this.setState({
        ukey: null,
        orderSelected: {
          ukey: response.id,
          doneByFeetSolutions: this.state.orderSelected.doneByFeetSolutions,
        },
      });
      return changeStatus({
        id: response.id,
        status: ORDER_STATUS.DELIVERED,
        UKEY: this.props.app.idUser,
        send: true,
      });
    });
  }

  createFixOrder() {
    console.log(this.state);
    const dataToSend = {
      duplicateUKey:
        this.state.ukey !== null ? null : this.state.orderSelected.ukey,
      price: this.state.price,
      doneByFeetSolutions: this.state.orderSelected.doneByFeetSolutions,
      description: this.state.description,
      ukey: this.state.ukey,
    };

    return new Promise((resolve, reject) => {
      setFix(dataToSend).then((response) => {
        if (response.response === 1) {
          if (response.updated === 1) {
            toast(`Se ha actualizado el arreglo correctamente`, {
              type: toast.TYPE.SUCCESS,
            });
            resolve(this.state.ukey);
          } else {
            this.setState({
              ...this.state,
              ukey: response.ukey,
            });

            toast("Se ha creado el arreglo correctamente", {
              type: toast.TYPE.SUCCESS,
            });
            resolve(response.ukey);
          }
        } else {
          toast("No se ha creado el arreglo correctamente", {
            type: toast.TYPE.ERROR,
          });
        }
      });
    });
  }

  handleOnChangePrice(price) {
    this.setState({
      price: +price,
    });
  }

  handleOnChangeDescription(newDescription) {
    this.setState({
      description: newDescription,
    });
  }
  handleChangeNewOrderForArreglo(order) {
    this.setState({
      orderSelected: {
        ...this.state.orderSelected,
        ...order,
      },
    });
  }

  getAllCustomers() {
    return new Promise((resolve, reject) => {
      if (this.isResponsibleRole()) {
        return getCustomers().then((result) => {
          if (result.response) {
            const data = result.customers.map((v) => {
              return { value: v.UKEY, label: v.NAME, discount: v.DISCOUNT };
            });
            this.setState({
              customers: data,
            });
            resolve(data);
          } else {
            reject("No se han podido recuperar los clientes.");
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  loadOrders() {
    const u = this.props?.match?.params?.ukey;
    // getAll Orders
    return getOrders(null, 0, 123).then((result) => {
      if (result.response) {
        this.setState({
          data: result.orders
            .filter((order) => order.ORDER_TYPE === ORDER_TYPE.NEW)
            .map((order) => ({
              name: `${order.NOMBRE_PACIENTE} ${order.APELLIDOS_PACIENTE}`,
              clinica: order.CLINIC_NAME,
              ukey: order.UKEY,
              material_base: order.M_BASE,
              numeroLote: order.ID_SERIES,
              discount: +order.PRICE_DISCOUNT,
            })),
        });
        if (u) {
          getOrders(u).then((result) => {
            if (result.orders.length > 0) {
              const data = JSON.parse(result.orders[0].DATA);
              this.handleInputChange({ value: data.orderToFix });
              this.setState({
                ukey: u,
                price: +result.orders[0].PRICE || 0,
                description: data.orderToFixDescription,
                status: result.orders[0].STATUS,
              });
              setTimeout(() =>
                this.setState({
                  orderSelected: {
                    ...this.state.orderSelected,
                    doneByFeetSolutions: data.doneByFeetSolutions,
                  },
                })
              );
            }
          });
        }
      } else {
        toast("error al obtener los pedidios", { type: toast.TYPE.ERROR });
      }
    });
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getAllCustomers()
      .then(this.loadOrders)
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {}

  isResponsibleRole = () =>
    this.props.app.idRol === USER_ROLES.ADMIN ||
    this.props.app.idRol === USER_ROLES.DESIGNER;

  render() {
    const options = [
      ...this.state.data.map((data) => ({
        value: data.ukey,
        label: `${data.numeroLote} - ${data.name}`,
      })),
    ];
    return (
      <Fragment>
        <Breadcrumb
          section={[
            { title: "Pedidos", url: "pedidos/" },
            { title: "Detalle Arreglo de un pedido" },
          ]}
        />
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className="arreglo-content">
            <Alert {...this.state.modalOpt} />
            <h2 className="title-section">Arreglo</h2>

            {this.isResponsibleRole() ? (
              <AdminView
                options={options}
                values={this.state}
                status={this.state.status}
                ukey={this.state.ukey}
                customers={this.state.customers}
                idUser={this.props.app.idUser}
                onSelectChange={this.handleInputChange}
                onPriceChange={this.handleOnChangePrice}
                onDescriptionChange={this.handleOnChangeDescription}
                onChangeStatus={this.handleChangeStatus}
                onChangeNewOrderForArreglo={this.handleChangeNewOrderForArreglo}
                onSubmit={this.handleOnSubmit}
              ></AdminView>
            ) : (
              <ClientView
                options={options}
                values={this.state}
                status={this.state.status}
                ukey={this.state.ukey}
                onSelectChange={this.handleInputChange}
                onDescriptionChange={this.handleOnChangeDescription}
                onChangeNewOrderForArreglo={this.handleChangeNewOrderForArreglo}
                onSubmit={this.handleOnSubmit}
                idUser={this.props.app.idUser}
              ></ClientView>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  order: state.orders,
  state,
});
const mapDispatchToProps = {
  updateOrder: updateOrderAction,
  resetStore: resetStoreAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Arreglo);
