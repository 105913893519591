import React from "react";
import { toast } from "react-toastify";
import Input from "../../../../components/input/input";
import Select from "../../../../components/select/select";
import TextArea from "../../../../components/text-area/text-area";
import { PRICE_IVA_ARREGLOS } from "../../../../core/constants/constants";
import { ORDER_STATUS } from "../../../../core/constants/roles";
import { calculatePriceWithIva } from "../../../../core/utils/price";
import { changeStatus as ChangeStatusService } from "../../../../resources/services";
import { CardArregloComponent } from "../components/card-arreglo";
import { DetailArreglo } from "../components/detail-arreglo";
import { NewOrderForArreglo } from "../components/new-order-for-arreglo";
import { ARREGLO_WITH_ORDER, ARREGLO_WITHOUT_ORDER } from "../utils";

export const AdminView = ({
  options,
  values,
  status,
  ukey,
  customers,
  onSelectChange,
  onPriceChange,
  onDescriptionChange,
  onChangeStatus,
  onChangeNewOrderForArreglo,
  onSubmit,
  idUser,
}) => {
  const changeStatus = (id, status) => {
    const dataToSend = {
      id: ukey || id,
      status: status,
      UKEY: idUser,
      send: true,
    };
    ChangeStatusService(dataToSend).then((response) => {
      if (response.response === 1 && response.update === 1) {
        onChangeStatus && onChangeStatus(status);
        toast(
          `Se ha pasado el arreglo a el estado ${
            status === ORDER_STATUS.DESIGN ? "diseño" : "fabricación"
          } correctamente`,
          {
            type: toast.TYPE.SUCCESS,
          }
        );
      } else {
        toast(
          `No se ha podido pasar el arreglo a ${
            status === ORDER_STATUS.DESIGN ? "diseño" : "fabricación"
          }`,
          { type: toast.TYPE.ERROR }
        );
      }
    });
  };

  console.log("values", values);
  const handleChangeStatus = () => {
    const newStatus =
      status === ORDER_STATUS.PROPOSAL
        ? ORDER_STATUS.DESIGN
        : ORDER_STATUS.FABRICATION;

    onSubmit().then((id) => {
      changeStatus(id, newStatus);
    });
  };

  return (
    <>
      <CardArregloComponent
        className="col-md-12 col-lg-6"
        title={"Selecciona el pedido para realizar el arreglo"}
      >
        <div className="row">
          <div>
            <div className="col-sm-12 text-left col-xs-4">
              <label
                htmlFor="molde"
                className="text-right control-label bold"
                disabled={status !== ORDER_STATUS.PROPOSAL || ukey !== null}
              >
                ¿Es una plantilla realizada por Feetsolutions?
              </label>
            </div>
            <div
              className="col-sm-12 col-xs-8"
              style={{ marginBottom: "3rem" }}
            >
              <div>
                <div>
                  <div className="input-content null">
                    <input
                      id="carga"
                      type="radio"
                      name="molde"
                      className="is-group"
                      disabled={
                        status !== ORDER_STATUS.PROPOSAL || ukey !== null
                      }
                      checked={
                        values.orderSelected.doneByFeetSolutions ===
                        ARREGLO_WITH_ORDER
                      }
                      value={ARREGLO_WITH_ORDER}
                      onChange={(e) =>
                        onSelectChange({ value: e.currentTarget.value })
                      }
                    />
                    <label htmlFor="carga" className="pre-label ">
                      Si
                    </label>
                  </div>
                </div>
                <div>
                  <div className="input-content null">
                    <input
                      id="carga"
                      type="radio"
                      name="molde"
                      className="is-group"
                      disabled={
                        status !== ORDER_STATUS.PROPOSAL || ukey !== null
                      }
                      checked={
                        values.orderSelected.doneByFeetSolutions ===
                        ARREGLO_WITHOUT_ORDER
                      }
                      value={ARREGLO_WITHOUT_ORDER}
                      onChange={(e) =>
                        onSelectChange({ value: e.currentTarget.value })
                      }
                    />
                    <label htmlFor="carga" className="pre-label">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {values.orderSelected.doneByFeetSolutions &&
          values.orderSelected.doneByFeetSolutions !==
            ARREGLO_WITHOUT_ORDER && (
            <Select
              options={options}
              virtualized={true}
              value={values.orderSelected.ukey}
              style={{ height: "112px", width: "100%" }}
              hideLabel={true}
              onChange={onSelectChange}
              notSaveInOrder
              isDisabled={status !== ORDER_STATUS.PROPOSAL || ukey !== null}
            />
          )}
        {values.orderSelected.doneByFeetSolutions === ARREGLO_WITHOUT_ORDER &&
          values.ukey && <DetailArreglo order={values.orderSelected} />}
        {values.orderSelected.doneByFeetSolutions &&
          values.orderSelected.doneByFeetSolutions !==
            ARREGLO_WITHOUT_ORDER && (
            <DetailArreglo order={values.orderSelected} />
          )}
        {values.orderSelected.doneByFeetSolutions === ARREGLO_WITHOUT_ORDER &&
          !values.ukey && (
            <NewOrderForArreglo
              onChange={onChangeNewOrderForArreglo}
              customers={customers}
            />
          )}
      </CardArregloComponent>
      <CardArregloComponent
        className="col-md-12 col-lg-6"
        title={"Precio del arreglo"}
      >
        <div className="card">
          <Input
            defaultValue={values.price}
            label={"precio"}
            name={"precio"}
            type="number"
            isolateComponent
            onChange={onPriceChange}
            dimensions={{ label: 1, element: 12 }}
            placeholder={"Precio del arreglo"}
            disabled={
              (status !== ORDER_STATUS.DESIGN &&
                status !== ORDER_STATUS.PROPOSAL) ||
              !values.orderSelected.ukey
            }
          />
          <div className="col-md-12 col-lg-6">
            Precio (Iva incl):{" "}
            {calculatePriceWithIva(values.price, 0, PRICE_IVA_ARREGLOS)}€
          </div>

          {values.orderSelected.discount ? (
            <div className="col-md-12 col-lg-6">
              Precio (Iva incl) + descuento:{" "}
              {calculatePriceWithIva(
                values.price,
                values.orderSelected.discount,
                PRICE_IVA_ARREGLOS
              )}
              €
            </div>
          ) : (
            ""
          )}
        </div>
      </CardArregloComponent>

      <CardArregloComponent
        className="col-md-12 col-lg-12"
        title={"Detalle de el arreglo"}
      >
        <TextArea
          disabled={
            status !== ORDER_STATUS.PROPOSAL && status !== ORDER_STATUS.DESIGN
          }
          defaultValue={values.description}
          style={{ height: "112px", width: "100%" }}
          isolateComponent
          onChange={onDescriptionChange}
        />
      </CardArregloComponent>
      {(status === ORDER_STATUS.PROPOSAL || status === ORDER_STATUS.DESIGN) && (
        <div className="col-md-12 col-lg-12">
          <button
            type="button"
            className="btn btn-white pull-right"
            onClick={handleChangeStatus}
            style={{ marginTop: "15px" }}
          >
            Pasar a{" "}
            {status === ORDER_STATUS.PROPOSAL ? "diseño" : "fabricación"}{" "}
            <i className="entypo-check"></i>
          </button>
        </div>
      )}
    </>
  );
};
